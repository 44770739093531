import i18n from '@/i18n/index'
import constant from '@/utils/constant.js'

export default {
  formData: [{
    title: i18n.t('text.basicInfo'),
    name: 0,
    createDateItem: [{
      span: 8,
      prop: 'redeem_code',
      name: 'input',
      label: i18n.t('form.luckyDraw.reedCode'),
      placeholder: i18n.t('placeholder.defaultInput'),
      Object: {
        maxlength: constant.FORM_OPTION.INPUT.MAX_LENGTH.COMMON
      }
    }, {
      span: 24,
      prop: 'remark',
      name: 'input',
      type: 'textarea',
      label: i18n.t('form.common.remark'),
      placeholder: i18n.t('placeholder.defaultInput'),
      Object: {
        maxlength: constant.FORM_OPTION.INPUT.MAX_LENGTH.COMMON,
        rows: 4
      }
    }]
  }]
}
